//Libraries
import React, { useEffect, useState, FunctionComponent } from 'react'
import cs from 'classnames'
import { Link } from 'gatsby'

//Types
import { SubNavBarTypes } from '../z_components-types'

//Styles
import styles from './css/subNavBar.module.scss'

//Static
import { getElementHeight } from '../../../static/reuseableFunctions'

const SubNavBar: FunctionComponent<SubNavBarTypes> = ({ links }) => {
  const [scrollPosition, setScrollPosition] = useState('top')

  useEffect(() => {
    window.onscroll = () => {
      if (
        window.pageYOffset >
          getElementHeight('welcome') + getElementHeight('header') &&
        window.pageYOffset <
          getElementHeight('header') +
            getElementHeight('welcome') +
            getElementHeight('layout') -
            window.innerHeight
      ) {
        setScrollPosition('fixed')
      } else if (
        window.pageYOffset <
        getElementHeight('welcome') + getElementHeight('header')
      ) {
        setScrollPosition('top')
      } else if (
        window.pageYOffset >
        getElementHeight('header') +
          getElementHeight('welcome') +
          getElementHeight('layout') -
          window.innerHeight
      ) {
        setScrollPosition('bottom')
      }
    }
    return () => {
      window.onscroll = null
    }
  }, [])

  return (
    <nav
      id="navbar"
      className={cs(
        scrollPosition === 'top'
          ? styles.subNavBarTop
          : scrollPosition === 'bottom'
          ? styles.subNavBarBottom
          : styles.subNavBarFixed
      )}
    >
      <span>Navigate</span>
      {links.map((item, index: number) => (
        <Link key={index} className={styles.subNavBarLink} to={item.link}>
          {item.name}
        </Link>
      ))}
    </nav>
  )
}

export default SubNavBar
