//Libraries
import React, { FunctionComponent } from 'react'

//Types
import {
  SeniorCenterDataType,
  SeniorCenterListTypes,
} from '../z_components-types'

//Styles
import styles from './css/seniorCenterList.module.scss'

//Components
import DirectionCard from '../../components/free/directionCard'
import SubNavBar from '../../components/free/subNavBar'

//Static
import { Counties } from '../../../static/localData'

const SeniorCenterList: FunctionComponent<SeniorCenterListTypes> = ({
  data,
}) => {
  const { allMarkdownRemark } = data
  const { edges } = allMarkdownRemark

  return (
    <>
      <SubNavBar
        links={[
          {
            name: 'Blaine County',
            link: '/senior-centers/#blaine',
          },
          { name: 'Camas County', link: '/senior-centers/#camas' },
          {
            name: 'Cassia County',
            link: '/senior-centers/#cassia',
          },
          {
            name: 'Gooding County',
            link: '/senior-centers/#gooding',
          },
          {
            name: 'Jerome County',
            link: '/senior-centers/#jerome',
          },
          {
            name: 'Lincoln County',
            link: '/senior-centers/#lincoln',
          },
          {
            name: 'Minidoka County',
            link: '/senior-centers/#minidoka',
          },
          {
            name: 'Twin Falls County',
            link: '/senior-centers/#twin falls',
          },
        ]}
      />

      {/* 
      For each county, creates a header for the county, and loops through all markdown nodes
      and finds the senior centers associated with that county, makes a card for each center
      */}
      {Counties.map((county: string) => (
        <div key={county}>
          <a
            id={county}
            href={`/senior-centers/#${county}`}
            style={{
              color: 'black',
              textDecoration: 'none',
              flexBasis: '100%',
              pointerEvents: 'none'
            }}
          >
            <h2 className="center">{county.toUpperCase()} COUNTY</h2>
          </a>
          <section className={styles.seniorCenterCards}>
            {edges
              .filter(
                (item: SeniorCenterDataType) =>
                  item.node.frontmatter.county.toLowerCase() ===
                  county.toLowerCase()
              )
              .map((item: SeniorCenterDataType) => {
                const location = item.node.frontmatter
                return (
                  <DirectionCard
                    key={item.node.fields.slug}
                    img={location.img}
                    title={location.name}
                    icon="ri-home-4-line"
                    infoFirstLine={location.address}
                    infoSecondLine={location.city}
                    links={[
                      {
                        name: 'See details',
                        link: `${item.node.fields.slug}`,
                      },
                    ]}
                  />
                )
              })}
          </section>
        </div>
      ))}
    </>
  )
}

export default SeniorCenterList
