//Libraries
import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//Context
import { FontSizeProvider } from '../context/fontSizeContext'

//Types
import { SeniorCenterTypes } from './z_page-types'

//Components
import Header from '../components/header/header'
import Layout from '../components/layout/layout'
import Footer from '../components/footer/footer'
import PageWelcome from '../components/header/pageWelcome'
import SeniorCenterList from '../components/senior-centers/seniorCenterList'
import PageBackground from '../components/free/pageBackground'

const SeniorCenters: FunctionComponent<SeniorCenterTypes> = ({ data }) => (
  <FontSizeProvider>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet="utf-8" />
        <title>OOA - Senior Centers</title>
        <link rel="canonical" href="https://ooa.csi.edu/senior-centers/" />
        <meta
          name="description"
          content="All senior centers in the Southern Idaho area associated with the Office on Aging."
        />
      </Helmet>

    <Header />

    <Layout>
      <PageWelcome
        img={data.markdownRemark.frontmatter.img}
        imgAltText="Senior centers banner image"
        title={data.markdownRemark.frontmatter.title}
      />
      <SeniorCenterList data={data} />
    </Layout>

    <Footer />
    <PageBackground />
  </FontSizeProvider>
)

export default SeniorCenters

//Queries data for the senior centers
export const SeniorCenterQuery = graphql`
  query seniorCenters {
    markdownRemark(
      frontmatter: { template_key: { eq: "senior-center-page" } }
    ) {
      frontmatter {
        title
        img {
          childImageSharp {
            fluid(maxWidth: 1700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { template_key: { eq: "senior-centers" } } }
    ) {
      edges {
        node {
          frontmatter {
            address
            city
            county
            img {
              childImageSharp {
                fluid(maxWidth: 1700, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
